<script>
  import { onMount, setContext, tick } from "svelte";
  import { t } from "../utilities/I18n";
  import { getHash } from "../utilities/url";
  import { track } from "../utilities/trackables";

  import Field from "../components/Field.svelte";
  import CategorySelector from "../components/CategorySelector.svelte";
  import SubsidySearch from "../components/SubsidySearch.svelte";

  export let town, sector, kind, contact_path, hints_path;

  setContext("sectorsByKind", town.sectors_by_kind || {});

  let fields = town.fields || [],
    searchTerm = "",
    subsidiesCount;

  const print = () => window.print();

  const countSubsidies = () => {
    let visibleFields = fields.filter((field) => sector == field.sector && kind == field.kind);

    subsidiesCount = visibleFields.reduce((sum, field) => {
      return sum + (field.subsidiesCount || 0);
    }, 0);
  };

  const scrollToTargetSubsidy = () => {
    let target = document.querySelector(getHash() || null);

    if (target) target.scrollIntoView();
  };

  const triggerTrackable = () => {
    track({ type: "print" });
  };

  onMount(() => {
    countSubsidies();
    scrollToTargetSubsidy();
    window.dispatchEvent(new Event("svelte-load"));
  });
</script>

<div class="subsidies-page-content">
  <div class="row">
    <h1 class="col-md-10 col-lg-8">
      {t("frontend.pages.subsidies.page_heading")}
      {town.zip}&nbsp;{town.name}
    </h1>
  </div>

  <div class="row">
    <h2 class="col-md-10 col-lg-8">
      {t("frontend.pages.subsidies.tagline", {
        for: t(`frontend.pages.subsidies.for.${sector}.${kind}`),
      })}
    </h2>
  </div>

  <div class="row">
    <CategorySelector bind:sector bind:kind on:change={countSubsidies} />
  </div>

  <div class="row">
    <SubsidySearch bind:searchTerm bind:subsidiesCount />
  </div>

  <div class="subsidies row">
    {#each fields as field (field.id)}
      {#if sector == field.sector && kind == field.kind}
        <Field {field} {sector} {kind} {searchTerm} on:count={countSubsidies} {hints_path} />
      {/if}
    {/each}
  </div>

  <div class="row">
    <div class="subsidies-footer col-md-10 d-print-none">
      <p>
        {@html t("frontend.pages.subsidies.contact_us_html", {
          contact_url: contact_path,
        })}
      </p>
      <div class="footer-buttons">
        <a href="#page-top" class="button-top btn">
          <svg
            data-name="Arrow "
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-arrow-u"
            width="31.93"
            height="31.93"
            viewBox="0 0 19.3 31.93"
            aria-label={t("frontend.pages.subsidies.back_to_top")}>
            <polyline points="12.63 15.96 0 28.59 3.34 31.93 19.3 15.96 3.34 0 0 3.34" />
          </svg>
        </a>
        <button class="button-print btn" on:click={triggerTrackable} on:click={print}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-print"
            width="9.22mm"
            height="11.15mm"
            viewBox="0 0 26.12 31.61"
            aria-label={t("frontend.pages.subsidies.print")}>
            <path
              d="M33.59,12.77H20.49a1.66,1.66,0,0,0-1.58,1.59V30.13a1.64,1.64,0,0,0,.48,1.1,1.61,1.61,0,0,0,1.1.48h16a1.67,1.67,0,0,0,1-.55,1.6,1.6,0,0,0,.49-1.1V17.15Zm2.51,17-15.27,0,0-15.08H33.47v1A1.44,1.44,0,0,0,34.9,17.2h1.2Z"
              transform="translate(-15.28 -12.77)" />
            <rect x="7.07" y="5.63" width="12.31" height="1.93" />
            <rect x="7.07" y="8.89" width="12.31" height="1.93" />
            <rect x="7.07" y="12.14" width="12.31" height="1.93" />
            <path
              d="M40.93,30.25a1.66,1.66,0,0,0-1.1-.48h-23a1.66,1.66,0,0,0-1.59,1.58V42.8a1.66,1.66,0,0,0,1.59,1.58h23a1.78,1.78,0,0,0,1-.55,1.66,1.66,0,0,0,.48-1.1V31.35A1.66,1.66,0,0,0,40.93,30.25ZM39.47,42.44l-22.27,0,0-10.75H39.47Z"
              transform="translate(-15.28 -12.77)" />
            <rect x="1.84" y="25.82" width="22.45" height="1.93" />
            <circle cx="21.63" cy="21.66" r="0.96" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<style>
  .row {
    justify-content: center;
  }

  /* footer */
  .subsidies-footer {
    margin-top: 50px;
  }
  .footer-buttons {
    text-align: center;
    margin-top: 50px;
  }
  .footer-buttons .btn {
    width: 50px;
    height: 50px;
    background-color: #c6c6c6;
    border-radius: 0;
  }
  .button-top {
    padding: 10px;
    margin-right: 10px;
  }

  .button-top .icon,
  .button-print .icon {
    fill: #0f0f0f;
  }
  .button-top:hover .icon,
  .button-print:hover .icon,
  .button-top:active .icon,
  .button-print:active .icon {
    fill: #f4f4f4;
  }

  .footer-buttons .icon-print {
    width: 23px;
    position: relative;
    top: -2px;
  }

  .footer-buttons .icon-arrow-u {
    height: 22px;
    transform: rotate(270deg);
    position: relative;
    left: -2px;
  }
</style>
